var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-overview", attrs: { fluid: "" } },
    [
      _c("header", { staticClass: "app-welcome-message" }, [
        _c("h1", [_vm._v("Vabi Excel importeren")]),
      ]),
      _c(
        "v-container",
        { attrs: { fluid: "", "py-0": "" } },
        [
          _c(
            "v-stepper",
            {
              attrs: { vertical: "" },
              model: {
                value: _setup.currentStep,
                callback: function ($$v) {
                  _setup.currentStep = $$v
                },
                expression: "currentStep",
              },
            },
            [
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    complete: _setup.currentStep > 1,
                    step: "1",
                    editable: "",
                  },
                },
                [_vm._v(" Bestand aanleveren ")]
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "1" } },
                [
                  _c(
                    "v-layout",
                    { staticClass: "d-flex flex-column form-group" },
                    [
                      _c("small", [_vm._v("Lever de Vabi Excel aan")]),
                      _c(
                        "v-alert",
                        { attrs: { outlined: "", type: "warning" } },
                        [
                          _vm._v(
                            "Controleer of alle VHE nummers gevuld zijn voordat u aan de upload begint."
                          ),
                        ]
                      ),
                      _c("vue-dropzone", {
                        ref: "importVabiDropzone",
                        attrs: {
                          id: "import-vabi-dropzone",
                          options: {
                            url: _setup.uploadUrl,
                            acceptedFiles: ".xlsx,.exe,.msi,.zip",
                            maxFilesize: 150000000,
                            maxFiles: 10,
                            disablePreviews: true,
                            chunking: true,
                            forceChunking: true,
                            thumbnailHeight: 200,
                            addRemoveLinks: true,
                            dictDefaultMessage:
                              "Sleep een bestand hier naar toe of klik om te uploaden.",
                            dictFileTooBig:
                              "Het bestand is te groot ({{filesize}}MiB). Maximale bestandsgrootte: {{maxFilesize}}MiB.",
                            dictInvalidFileType:
                              "Het is niet mogelijk bestanden van dit type te uploaden (toegestaan: .xlsx).",
                            dictResponseError:
                              "De Server reageerde met code {{statusCode}}.",
                            dictRemoveFile: "Verwijder bestand",
                            dictMaxFilesExceeded:
                              "U kunt maximaal 1 bestand uploaden.",
                          },
                        },
                        on: {
                          "vdropzone-success": _setup.onUploaded,
                          "vdropzone-error": _setup.onUploadError,
                        },
                      }),
                    ],
                    1
                  ),
                  _setup.copyToStorage.length > 0
                    ? _c("v-layout", [
                        _vm._v("Bestand wordt klaargezet in de cloud"),
                        _c("span", { staticClass: "loadingDots" }),
                      ])
                    : _vm._e(),
                  _setup.copyToStorageError
                    ? _c("v-alert", { attrs: { type: "error" } }, [
                        _vm._v("Probleem bij het uploaden."),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    complete: _setup.currentStep > 2,
                    step: "2",
                    editable: "",
                  },
                },
                [_vm._v(" Import ruwe data ")]
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "2" } },
                [
                  _setup.status
                    ? _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "2" } }, [
                                _vm._v(" Tijdstip "),
                              ]),
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _vm._v("Bestand"),
                              ]),
                              _c("v-col", [_vm._v("Resultaat")]),
                            ],
                            1
                          ),
                          _vm._l(_setup.status, function (item, index) {
                            return [
                              _c(
                                "v-row",
                                { key: index },
                                [
                                  _c("v-col", { attrs: { cols: "2" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _setup.formatDateUtcDT(
                                            item.uploadDate
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _vm._v(_vm._s(item.filename) + " "),
                                  ]),
                                  _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-container",
                                        { attrs: { fluid: "", "pa-0": "" } },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "2" } },
                                                [_vm._v("Upload")]
                                              ),
                                              _c(
                                                "v-col",
                                                [
                                                  item.uploadStatus == "Success"
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "green",
                                                          },
                                                        },
                                                        [_vm._v("mdi-check")]
                                                      )
                                                    : item.uploadStatus ==
                                                      "None"
                                                    ? _c("v-icon", [
                                                        _vm._v("mdi-tilde"),
                                                      ])
                                                    : _c("v-icon", [
                                                        _vm._v(
                                                          "mdi-close-octagon-outline"
                                                        ),
                                                      ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "2" } },
                                                [_vm._v("Controle")]
                                              ),
                                              _c(
                                                "v-col",
                                                [
                                                  item.excelStatus == "Success"
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "green",
                                                          },
                                                        },
                                                        [_vm._v("mdi-check")]
                                                      )
                                                    : item.excelStatus == "Busy"
                                                    ? [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-timer-sand"
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          " Bezig met controleren "
                                                        ),
                                                        _c("span", {
                                                          staticClass:
                                                            "loadingDots",
                                                        }),
                                                      ]
                                                    : item.excelStatus ==
                                                      "Warning"
                                                    ? [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "orange",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-alert-outline"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._l(
                                                          item.excelErrors,
                                                          function (
                                                            err,
                                                            index2
                                                          ) {
                                                            return _c(
                                                              "v-row",
                                                              {
                                                                key: index2,
                                                                attrs: {
                                                                  dense: "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "auto",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _setup.message(
                                                                            err.id
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c("v-col", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      err.name
                                                                    )
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                      ]
                                                    : item.excelStatus ==
                                                      "Failed"
                                                    ? [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "red",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-close-octagon-outline"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._l(
                                                          item.excelErrors,
                                                          function (
                                                            err,
                                                            index2
                                                          ) {
                                                            return _c(
                                                              "v-row",
                                                              {
                                                                key: index2,
                                                                attrs: {
                                                                  dense: "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-col",
                                                                  {
                                                                    attrs: {
                                                                      cols: "auto",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _setup.message(
                                                                            err.id
                                                                          )
                                                                        ) +
                                                                        " "
                                                                    ),
                                                                  ]
                                                                ),
                                                                _c("v-col", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      err.name
                                                                    )
                                                                  ),
                                                                ]),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                      ]
                                                    : _c("v-icon", [
                                                        _vm._v("mdi-tilde"),
                                                      ]),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-row",
                                            { attrs: { dense: "" } },
                                            [
                                              _c(
                                                "v-col",
                                                { attrs: { cols: "2" } },
                                                [_vm._v("Import")]
                                              ),
                                              _c(
                                                "v-col",
                                                [
                                                  item.importExcelStatus ==
                                                  "Success"
                                                    ? [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "green",
                                                            },
                                                          },
                                                          [_vm._v("mdi-check")]
                                                        ),
                                                      ]
                                                    : item.importExcelStatus ==
                                                      "Busy"
                                                    ? [
                                                        _c("v-icon", [
                                                          _vm._v(
                                                            "mdi-timer-sand"
                                                          ),
                                                        ]),
                                                        _vm._v(
                                                          " Bezig met inlezen "
                                                        ),
                                                        item.excelStatusProgress
                                                          ? _c("span", [
                                                              _vm._v(
                                                                "'" +
                                                                  _vm._s(
                                                                    item.excelStatusProgress
                                                                  ) +
                                                                  "'"
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                        _c("span", {
                                                          staticClass:
                                                            "loadingDots",
                                                        }),
                                                      ]
                                                    : item.importExcelStatus ==
                                                      "Failed"
                                                    ? [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              color: "red",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "mdi-close-octagon-outline"
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    : _c("v-icon", [
                                                        _vm._v("mdi-tilde"),
                                                      ]),
                                                ],
                                                2
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c("v-divider", { key: `sel-div-${index}` }),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    complete: _setup.currentStep > 3,
                    step: "3",
                    editable: "",
                  },
                },
                [_vm._v(" Gegevens overbrengen naar pandenlijst ")]
              ),
              _c(
                "v-stepper-content",
                { attrs: { step: "3" } },
                [
                  _setup.status
                    ? _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", { attrs: { cols: "2" } }, [
                                _vm._v(" Tijdstip "),
                              ]),
                              _c("v-col", { attrs: { cols: "3" } }, [
                                _vm._v("Bestand"),
                              ]),
                              _c("v-col", { attrs: { cols: "4" } }, [
                                _vm._v("Partijnaam"),
                              ]),
                              _c("v-col", [_vm._v("Resultaat")]),
                            ],
                            1
                          ),
                          _vm._l(_setup.statusImport, function (item, index) {
                            return [
                              _c(
                                "v-row",
                                { key: index },
                                [
                                  _c("v-col", { attrs: { cols: "2" } }, [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _setup.formatDateUtcDT(
                                            item.importDate
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                  _c("v-col", { attrs: { cols: "3" } }, [
                                    _vm._v(_vm._s(item.filename) + " "),
                                  ]),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "" } },
                                    [
                                      item.importCllStatus == "WaitForInput"
                                        ? [
                                            _c(
                                              "v-container",
                                              {
                                                attrs: {
                                                  fluid: "",
                                                  "pa-0": "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "v-row",
                                                  [
                                                    _c(
                                                      "v-col",
                                                      {
                                                        staticClass:
                                                          "d-flex flex-nowrap",
                                                      },
                                                      [
                                                        _vm._v("Woningen: "),
                                                        _c("v-text-field", {
                                                          staticClass:
                                                            "noBorders",
                                                          attrs: {
                                                            "hide-details":
                                                              "auto",
                                                            outlined: "",
                                                            dense: "",
                                                          },
                                                          model: {
                                                            value:
                                                              _setup.partyName[
                                                                item.importId
                                                              ],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _setup.partyName,
                                                                item.importId,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "partyName[item.importId]",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "v-col",
                                                      [
                                                        _c(
                                                          "v-btn",
                                                          {
                                                            staticClass:
                                                              "primary",
                                                            attrs: {
                                                              disabled:
                                                                !_setup.checkPartyNameInput(
                                                                  item.importId
                                                                ),
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _setup.pushImport(
                                                                  item.importId
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  dark: "",
                                                                  left: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-pencil-circle"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              " Verwerken "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        : _c("span", [
                                            _vm._v(_vm._s(item.partyName)),
                                          ]),
                                    ],
                                    2
                                  ),
                                  _c(
                                    "v-col",
                                    [
                                      item.importCllStatus == "Success"
                                        ? [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "green" } },
                                              [_vm._v("mdi-check")]
                                            ),
                                            item.complexesTotal &&
                                            !item.complexesNumber &&
                                            item.housesTotal &&
                                            !item.housesNumber
                                              ? _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.complexesTotal
                                                    ) +
                                                      " complex(en) - " +
                                                      _vm._s(item.housesTotal) +
                                                      " woning(en)"
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        : item.importCllStatus == "WaitForInput"
                                        ? [
                                            _c("v-icon", [
                                              _vm._v("mdi-timer-sand-paused"),
                                            ]),
                                            _vm._v(" Wacht op input"),
                                            _c("span", {
                                              staticClass: "loadingDots",
                                            }),
                                          ]
                                        : item.importCllStatus == "Busy"
                                        ? [
                                            _c("v-icon", [
                                              _vm._v("mdi-timer-sand"),
                                            ]),
                                            _vm._v(" Bezig met inlezen"),
                                            _c("span", {
                                              staticClass: "loadingDots",
                                            }),
                                            item.complexesTotal
                                              ? _c("div", [
                                                  _vm._v(
                                                    " complex " +
                                                      _vm._s(
                                                        item.complexesNumber
                                                      ) +
                                                      "/" +
                                                      _vm._s(
                                                        item.complexesTotal
                                                      ) +
                                                      " "
                                                  ),
                                                  item.housesTotal &&
                                                  item.housesTotal > 0 &&
                                                  item.housesNumber <=
                                                    item.housesTotal
                                                    ? _c("span", [
                                                        _vm._v(
                                                          ", woning " +
                                                            _vm._s(
                                                              item.housesNumber
                                                            ) +
                                                            "/" +
                                                            _vm._s(
                                                              item.housesTotal
                                                            )
                                                        ),
                                                      ])
                                                    : item.housesTotal &&
                                                      item.housesNumber >
                                                        item.housesTotal
                                                    ? _c("span", [
                                                        _vm._v(", afronden"),
                                                      ])
                                                    : _vm._e(),
                                                ])
                                              : _vm._e(),
                                          ]
                                        : item.importCllStatus == "Failed"
                                        ? [
                                            _c(
                                              "v-icon",
                                              { attrs: { color: "red" } },
                                              [
                                                _vm._v(
                                                  "mdi-close-octagon-outline"
                                                ),
                                              ]
                                            ),
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(item.complexesTotal) +
                                                  " complex(en) - " +
                                                  _vm._s(item.housesTotal) +
                                                  " woning(en)"
                                              ),
                                            ]),
                                          ]
                                        : _c("v-icon", [_vm._v("mdi-tilde")]),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                              _vm._l(item.importErrors, function (err, index3) {
                                return _c(
                                  "v-row",
                                  {
                                    key: `importErrors-${index3}`,
                                    attrs: { dense: "" },
                                  },
                                  [
                                    _c("v-col", { attrs: { cols: "1" } }),
                                    _c("v-col", [
                                      _vm._v(_vm._s(err.reference)),
                                    ]),
                                    _c("v-col", { attrs: { cols: "auto" } }, [
                                      _vm._v(":"),
                                    ]),
                                    _c("v-col", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _setup.message(err.messageId)
                                          ) +
                                          " "
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              }),
                              _c("v-divider", { key: `sel-div-${index}` }),
                            ]
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-container",
        { attrs: { fluid: "", "py-0": "" } },
        [_c("v-row", [_c("v-col")], 1)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }